import './App.css';
import ProtectedRoute from './routes/ProtectedRoute';

function App() {
  return (
    <>
    <ProtectedRoute></ProtectedRoute>
    </>
  );
}

export default App;
