import React from 'react';
import NavBar from '../components/NavBar';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from '../pages/Home';
import Clothes from '../pages/Clothes';
import Sale from '../pages/Sale';
import Events from '../pages/Events';
import Contact from '../pages/Contact';
import Login from '../pages/Login';


export default function ProtectedRoute(){
    return (
        <Router>
        <NavBar/>
        <Routes>
            <Route path='/' exact Component={Home} />
            <Route path='/clothes' Component={Clothes} />
            <Route path='/clothes' Component={Clothes} />
            <Route path='/sale' Component={Sale} />
            <Route path='/events' Component={Events} />
            <Route path='/contact' Component={Contact} />
            <Route path='/log-in' Component={Login} />
        </Routes>
      </Router>
    )
};