import React from 'react';
import './LocationInfo.css';
import FB from '../Images/FB.png';
import WX from '../Images/WX.JPG';

const LocationInfo = () => {
  return (
    <div className="location-container">
      <h2>To find our new shopfront, visit us at</h2>
      <p className="email-address">20 magnet court, Sandy Bay, TAS 7005</p>
    </div>
    
  );
};

export default LocationInfo;
