import React from 'react';
import Footer from '../components/Footer';
import Products from '../components/Products';
import EventBanner from '../components/EventBanner';

export default function Sale(props) {

  return (
    <div>
      <h1 className='sale'>SALES</h1>
      <Products/>
      <Footer/>
    </div>

  )
}
