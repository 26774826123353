export const StoreImageData = [

    {
      image:
        '/Img/s1.JPG'
    },
    {
      image:
        '/Img/s2.JPG'
    },
    {
      image:
        '/Img/s3.JPG'
    },
    {
      image:
        '/Img/s4.JPG'
    },
    {
      image:
        '/Img/s5.JPG'
    }

  ];
  