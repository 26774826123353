import React from 'react';
import './ADdisplay.css';

const ADdisplay = (props) => {

  return (
    <div className="ADcontainer">
       <div className="wrapper">
         <div className="color_block"></div>
         <div className="ad">
         <img
              className='Products__item__img'
              alt='products'
              src={props.src}
            />
         </div>
       </div>
    </div>
  );
}

export default ADdisplay;
