import React from 'react';
import './Memberships.css';
import Membership from './Membership';
import Topup from '../Images/Membership.png';

function Memberships() {
  return (
    <div className='memberships'>
      <div className='memberships__container'>
        <div className='memberships__wrapper'>
          <ul className='memberships__items'>
            <Membership
              src={Topup}
              text='Dimond VIP Top-Up'
              price='AU$1088.00'
              label='Get $100 Extra'
              path='/events'
            />
            <Membership
              src={Topup}
              text='Dimond VIP Top-Up'
              price='AU$688.00'
              label='Get $50 Extra'
              path='/events'
            />
            <Membership
              src={Topup}
              text='Dimond VIP Top-Up'
              price='AU$388.00'
              label='Get $30 Extra'
              path='/events'
            />
            <Membership
              src={Topup}
              text='Dimond VIP Top-Up'
              price='AU$188.00'
              label='Get $10 Extra'
              path='/events'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Memberships;
