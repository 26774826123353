export const ImageSliderData = [

    {
      image:
        '/Img/e4.jpg'
    },
    {
      image:
        '/Img/e3.jpg'
    },
    {
      image:
        '/Img/e6.jpg'
    },
    {
      image:
        '/Img/e7.jpg'
    },
    {
      image:
        '/Img/e8.jpg'
    },
    {
      image:
        '/Img/e5.jpg'
    },
    {
      image:
        '/Img/e9.jpg'
    },
    {
      image:
        '/Img/e10.jpg'
    },
    {
      image:
        '/Img/e11.jpg'
    },
    {
      image:
        '/Img/e2.jpg'
    }

  ];
  