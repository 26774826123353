import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Images/Logogo.png';
import Brand from '../Images/F.png';
import UserIcon from '../Images/UserIcon.png';
import { Button } from './Button';
import './Navbar.css';
// <img src={Logo} alt="ForU Logo"/>
// {button && <Button buttonStyle={'btn--outline'}>SIGN UP</Button>}
function NavBar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if(window.innerWidth <=960) {
        setButton(false);
    }else {
        setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    },[]);


    window.addEventListener('resize', showButton);

    return (
    <>
      <nav className="navbar">
        <div className="navbar-container">

            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                <img src={Logo} alt="ForU Logo" className='logo-img'/>
                <img src={Brand} alt="ForU Brand" className='logo-text'/>
            </Link>
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fa-sharp fa-solid fa-xmark' : 'fa-sharp fa-solid fa-bars'}/>
            </div>


            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                    <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                    Home
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/clothes' className='nav-links' onClick={closeMobileMenu}>
                    Clothing
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/sale' className='nav-links' style={{color:`#fa5d5d`}} onClick={closeMobileMenu}>
                    Sale
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/events' className='nav-links' onClick={closeMobileMenu}>
                    Events
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                    Contact
                    </Link>
                </li>
                <li className='nav-item'>
                    <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
                    Sign Up
                    </Link>
                </li>
            </ul>
            <Link to='/log-in' className='navbar-log-in' onClick={closeMobileMenu}>
                <img src={UserIcon} alt="UserLoginIcon" className='login-icon'/>
                Log In
            </Link>
        </div>

      </nav>
    </>
  )
}

export default NavBar
 