import React from 'react';
import './Welcome.css';
import pinklogo from '../Images/Logogo.png';
import specialOffer from '../Images/Specialoffers.png';


function WelcomeComponent() {
  const welcometext = "WELCOME : )";
  
  return (
    <div className="welcome-component">
      <div className="pinklogo">
        <img className="pinklogo-img"src={pinklogo} alt="For U Pty Ltd" />
      </div>
      <div className="content">
        <div className="welcome-text">
          <h1 className="welcome-heading">{welcometext} </h1>
          <p className="welcome-content">Welcome to ForU (Hobart). We are excited to join the Hobart community to bring on-trend garments, in-style accessories and other fashionable products to fashion lovers in Tasmania.</p>
          <button className="welcome-button">More About Our Brand</button>
        </div>
        <div className="special-offer">
          <img className="special-offer-img" src={specialOffer} alt="Special Offers" />
        </div>
      </div>
      <div className="decorative-block"></div>
    </div>
  );
}

export default WelcomeComponent;
