import React from 'react';
import Footer from '../components/Footer';
import ContactInfo from '../components/ContactInfo';
import LocationInfo from '../components/LocationInfo';
import ImageSlider from '../components/ImageSlider';
import { StoreImageData } from '../components/StoreImageData'; 

export default function Contact() {
  return (
    <div>
      <h1 className='contact'>CONTACT</h1>
      <ContactInfo/>
      <LocationInfo/>
      <ImageSlider slides={StoreImageData} />
      

      <Footer/>
    </div>

  )
}
