import React from 'react'
import HeroSection from '../components/HeroSection'
import Cards from '../components/Cards'
import Footer from '../components/Footer'
import DisplayVideo from '../components/DisplayVideo'

import WelcomeComponent from '../components/Welcome'

function Home() {
  return (
    <div>
        <HeroSection/>
        <WelcomeComponent/>
        <DisplayVideo/>
        <Cards/>
        <Footer/>
    </div>
  )
}

export default Home
