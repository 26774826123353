import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import Pic1 from '../Images/1.jpg';
import Pic2 from '../Images/2.JPG';
import Pic3 from '../Images/3.JPG';
import Pic4 from '../Images/4.JPG';
import Pic5 from '../Images/5.JPG';

function Cards() {
  return (
    <div className='cards'>
      <h1>Our Best Sellers</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={Pic1}
              text='Sweatpants With Teddy Bear Detail'
              price='AU$78.00'
              label='Best Seller'
              path='/clothes'
            />
            <CardItem
              src={Pic2}
              text='Fluffy Furry Coat'
              price='AU$98.00'
              label='Best Seller'
              path='/clothes'
            />
            <CardItem
              src={Pic3}
              text='Blazer With 3M-Reflective
              Splashed Ink'
              price='AU$128.00'
              label='Best Seller'
              path='/clothes'
            />
            <CardItem
              src={Pic4}
              text='Black Top With Bow/-knots'
              price='AU$78.00'
              label='Best Seller'
              path='/services'
            />
          </ul>
          {/* <ul className='cards__items'>
            <CardItem
              src={Pic}
              text='Set Sail in the Atlantic Ocean visiting Uncharted Waters'
              label='Mystery'
              path='/services'
            />
            <CardItem
              src={Pic}
              text='Experience Football on Top of the Himilayan Mountains'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src={Pic}
              text='Ride through the Sahara Desert on a guided camel tour'
              label='Adrenaline'
              path='/sign-up'
            />
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default Cards;
