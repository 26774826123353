import React from 'react';
import './Products.css';
import Product from './Product';
import Pic1 from '../Images/1.jpg';
import Pic2 from '../Images/2.JPG';
import Pic3 from '../Images/3.JPG';
import Pic4 from '../Images/4.JPG';
import Pic5 from '../Images/5.JPG';
import Pic6 from '../Images/6.JPG';
import Pic7 from '../Images/7.JPG';
import Pic8 from '../Images/8.JPG';

function Products() {
  return (
    <div className='Products'>
      <div className='Products__container'>
        <div className='Products__wrapper'>
          <ul className='Products__items'>
            <Product
              src={Pic1}
              text='Sweatpants With Teddy Bear Detail'
              price='AU$78.00'
              label='Best Seller'
              path='/clothes'
            />
            <Product
              src={Pic2}
              text='Fluffy Furry Coat'
              price='AU$98.00'
              label='Best Seller'
              path='/clothes'
            />
            <Product
              src={Pic3}
              text='Blazer With 3M-Reflective
              Splashed Ink'
              price='AU$128.00'
              label='Best Seller'
              path='/clothes'
            />
            <Product
              src={Pic4}
              text='Black Top With Bow/-knots'
              price='AU$78.00'
              label='Best Seller'
              path='/clothes'
            />
          </ul>
          <ul className='Products__items'>
            <Product
              src={Pic5}
              text='Tie Dyed Knitted Top'
              price='AU$68.00'
              label='SALES'
              path='/clothes'
            />
            <Product
              src={Pic6}
              text='Knitted Top with V-shaped Neckline'
              price='AU$48.00'
              label='SALES'
              path='/clothes'
            />
            <Product
              src={Pic7}
              text='Asymmetric Knitted Top With a Novelty Design'
              price='AU$78.00'
              path='/clothes'
            />
            <Product
              src={Pic8}
              text='Red Sweater With Heart Patterns'
              price='AU$68.00'
              label='SALES'
              path='/clothes'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Products;
