import React from 'react';
import Footer from '../components/Footer';
import Memberships from '../components/Memberships';
import EventBanner from '../components/EventBanner';
import ADdisplay from '../components/ADdisplay'
import buy3get1 from '../Images/3get1.png';
import Topup from '../Images/Membership.png';
import ImageSlider from '../components/ImageSlider';
import { ImageSliderData } from '../components/ImageSliderData'; 


export default function Events(props) {

  const membership = {
    title:'The Big Deal',
    line1:'JOIN US TODAY!',
    line2:'get your',
    line3:'Membership Awards and Discounts'
  }
  const offer = {
    title:'Special Offer',
    line1:"Don't miss out the deal!",
    line2:'"Buy 3 get 1 Free"',
    line3:'Limited time ONLY'
  }

  const event = {
    title:'DIY Workshops',
    line1:"Let's meet & have fun",
    line2:'$30 pp; $50 for 2p',
    line3:'Book NOW & pay LATER :)'
  }

  return (
    <div>
      <h1 className='events'>EVENTS</h1>
      <EventBanner props={membership}/>
      <Memberships/>
      <ADdisplay src={Topup}/>
      <EventBanner props={offer}/>
      <ADdisplay src={buy3get1}/>
      <EventBanner props={event}/>
      <ImageSlider slides={ImageSliderData} />
      <Footer/>
    </div>

  )
}
