import React from 'react';
import { Link } from 'react-router-dom';
// import './Product.css';

function Membership(props) {
  return (
    <>
      <li className='memberships__item'>
        <Link className='memberships__item__link' to={props.path}>
          <figure className='memberships__item__pic-wrap' data-category={props.label}>
            <img
              className='memberships__item__img'
              alt='memberships'
              src={props.src}
            />
            </figure>
          <div className='memberships__item__info'>
            <h5 className='memberships__item__text'>{props.text}</h5>
          </div>
          <div className='memberships__item__priceinfo'>
          <h5 className='memberships__item__price'>{props.price}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default Membership;