import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import {useNavigate} from 'react-router-dom';


function HeroSection() {

  const navigate = useNavigate();

  const navigateToClothes = () => {
    // 👇️ navigate to /Clothess
    navigate('/clothes');
  };


  return (
    <div className='hero-container'>
      <video src='/videos/BGVideo.mp4' autoPlay loop muted />
      <h1>FOR U</h1>
      <p>ONLY FOR YOU</p>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={navigateToClothes}
        >
          SHOP ALL NOW  
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;

