import React from 'react';
import Products from '../components/Products';
import Footer from '../components/Footer'

export default function Clothes() {
  return (
  <div>
  <h1 className='clothes'>CLOTHING</h1>
  <Products/>
  <Footer/>
  </div>


  )
}
