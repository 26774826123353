import React from 'react';
import './EventBanner.css';

const EventBanner = (props) => {
    const EventProps = props.props;

    return (
        <div className="banner">
            <div className='wrapper'>
            <div className="banner-text">
                <h1>- {EventProps.title} -</h1>
                <h5>{EventProps.line1}</h5>
                <h5>{EventProps.line2}</h5>
                <h5>{EventProps.line3}</h5>
            </div>
            </div>
        </div>
    );
};

export default EventBanner;
