import React from 'react';
import './ContactInfo.css';
import FB from '../Images/FB.png';
import WX from '../Images/WX.JPG';

const ContactInfo = () => {
  return (
    <div className="contact-container">
      <h2>For all inquiries, questions or concerns, reach us at</h2>
      <p className="email-address">foru.pty.ltd@gmail.com</p>
      <p>OR</p>
      <p>Scan our QR Code for:</p>
      <div className="qr-codes">
        <div className="qr-code">
          <img src={FB} alt="Facebook QR" />
          <p>FaceBook</p>
        </div>
        <div className="qr-code">
          <img src={WX} alt="WeChat QR" />
          <p>WeChat</p>
        </div>
      </div>
      <p className="live-chat-info">We're also available on Live Chat, during regular business hours on Eastern Standard Time</p>
    </div>
  );
};

export default ContactInfo;
