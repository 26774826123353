import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Logo from '../Images/Logogo.png';
import Brand from '../Images/F.png';


function Footer() {

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the FORU newsletter to receive our best fashion deals
        </p>
        <p className='footer-subscription-text'>
          Fashion trends that only made for you.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Type your email here :)'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
                <img src={Logo} alt="ForU Logo" className='logo-img'/>
                <img src={Brand} alt="ForU Brand" className='logo-text'/>
            </Link>
          </div>
          <small class='website-rights'>FORU PTY LTD © 2020</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/foruhobart'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/foruhobart/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='https://www.pinterest.com.au/foruhobart/'
              target='_blank'
              aria-label='Pinterest'
            >
              <i class='fa-brands fa-pinterest' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
