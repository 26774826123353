import React from 'react';
import './DisplayVideo.css';

const DisplayVideo = (props) => {

  return (
    
    <div className="Videocontainer">
               <h1>Our Grand Openning</h1>

       <div className="wrapperv">
         <div className="color_blockv"></div>
         <div className="adv">
         <video className='Products__item__video' src='/videos/Shopfront.MP4' autoPlay loop muted />
         </div>
       </div>
    </div>
  );
}

export default DisplayVideo;
